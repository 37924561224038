
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

h1, h2{
  font-family: 'DM-Serif-Display',serif;
}

h3, h4, h5, h6, p, a {
  font-family: 'Manrope',sans-serif;
}
  